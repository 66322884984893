import React from "react";
import { FormattedMessage } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";
import { ChartPieIcon, ChartBarIcon, CalendarIcon, ClipboardDocumentCheckIcon, DocumentChartBarIcon, BanknotesIcon, CurrencyDollarIcon, BriefcaseIcon, TicketIcon, StarIcon, RocketLaunchIcon, ArrowUpRightIcon } from '@heroicons/react/24/outline'
import { ReactComponent as FastFood } from '@material-design-icons/svg/outlined/fastfood.svg';

export default class MenuItemText extends React.Component {


	render() {
		const { item, parentItem, isActive } = this.props;

		const iconComponents = {
			dashboard: ChartPieIcon,
			horario_pico: ChartBarIcon,
			agendamento: CalendarIcon,
			bar_cozinha: FastFood,
			gestao: ClipboardDocumentCheckIcon,
			relatorios: DocumentChartBarIcon,
			caixa: BanknotesIcon,
			financeiro: CurrencyDollarIcon,
			minha_empresa: BriefcaseIcon,
			clube_assinante: TicketIcon,
			dpote: StarIcon,
			pump: RocketLaunchIcon
		};

		const rightIconComponents = {
			pump: ArrowUpRightIcon
		}

		return (
			<>
				{item.icon && iconComponents[item.icon] &&
					<div style={{ width: 30, marginRight: 12, display: 'grid' }}>
						{item.icon == 'bar_cozinha' ? <i class="material-symbols-outlined" style={{ color: '#666' }} >fastfood</i> : React.createElement(iconComponents[item.icon], { className: `sidemenu-icon-v2`, width: 24, height: 24 })}
					</div>
				}

				{parentItem && parentItem.bullet === "dot" && (
					<i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
						<span />
					</i>
				)}

				{parentItem && parentItem.bullet === "line" && (
					<i className="kt-menu__link-bullet kt-menu__link-bullet--line">
						<span />
					</i>
				)}

				<span className="kt-menu__link-text" style={{ whiteSpace: "nowrap" }}>
					{!item.translate ? (
						item.title
					) : (
						<FormattedMessage id={item.translate} defaultMessage={item.title} />
					)}
				</span>

				{item.badge && (
					<span className="kt-menu__link-badge">
						<span className={`kt-badge ${item.badge.type}`}>
							{item.badge.value}
						</span>
					</span>
				)}

				{item.rightIcon && (
					React.createElement(rightIconComponents[item.rightIcon], { className: 'sidemenu-right-icon-v2', width: 16, height: 16 })
				)}

				{item.submenu && <i className="kt-menu__ver-arrow la la-angle-down" />}
			</>
		);
	}
}
